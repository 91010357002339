/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Header from  '../Header';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { withNamespaces } from 'react-i18next';
import { ParseMarkdown } from '../../lib/ParseMarkdown';

const ScScale = props => {
  const [submitted, setSubmitted] = useState(false);
  const question_id = props.bag.question.id;
  const { question , questionsLength , questionNumber, modulsLength, modulNumber,
    logo_url, allow_back, allow_later, allow_dummydata, title, timelimit_seconds } = props.bag;
  const setDivs = props.bag.question.scales;
  const divsInPage = [];
  function nextPos(i) {
    if (setDivs[i+1]) {
      return setDivs[i+1].position;
    }
  }

  const answers = props.bag.values;
  const answer = answers.find(v => v[0].question_id === props.bag.question.id);
  if(typeof answer !== 'undefined' && submitted === false) {
    let submit = { answer_id: answer.value, value: ''}
    setSubmitted(submit)
  }

  for (let index = 0; index < setDivs.length;) {
    var curent = setDivs[index].position;
    var { text } = setDivs[index];
    var next = nextPos(index);
    divsInPage.push(
      <div className="sc_scale_combi" key={index}>
        <div className='sc_scale_singleanswers'>
          {setButtons(text, curent, next, answer)}
        </div>
        <div className='sc_scale_label'>
          {text}
        </div>
      </div>);
    index++;
  }

  function setButtons(text, curent, next, answer) {
    if (!next) {
      next = 11;
    }
    const buttons = [];
    do  {
      let isAnswer = false;
      if(typeof submitted.answer_id !== "undefined" && submitted.answer_id ===curent) {
        isAnswer = true;
      }
      if(typeof answer !== "undefined" && answer[0].value === curent && typeof submitted.answer_id === "undefined") {
        isAnswer = true;
      }
      buttons.push(
        <div className="singleanswer" key={curent}>
          <input type="radio" value={<ParseMarkdown src={text} /> } text={text} name="given_answer" id={curent}
            checked={ isAnswer === true ? true : submitted.answer_id === curent }
            onChange={ e => onChange(e)}
          />
          <label htmlFor={curent} >
            <span className="accesskey">{curent}</span>
          </label>
        </div>
      );
      curent ++;
    } while (curent < next);
    return buttons;
  };

  function onChange(event) {
    const answer  = {};
    const { id } = event.target;
    const text =  event.target.getAttribute('text');
    const answer_id = parseInt(id, 10);
    answer.answer_id = answer_id;
    answer.value = text;
    setSubmitted(answer);
  }

  function  handleSubmit() {
    let value = submitted.answer_id
    if(typeof value === "undefined") {
      value = answer[0].value;
    }
    const answerToSend = [{ question_id, value: value }];
    props.functionsBag.callbackFromQestion(answerToSend);
  };

  function presskey(key) {
    const findNode = document.getElementById(key);
    if (findNode) {
      const answer = {};
      const { id } = findNode;
      const text = findNode.getAttribute('text');
      const answer_id = parseInt(id, 10);
      answer.answer_id = answer_id;
      answer.value = text;
      setSubmitted(answer);
    }
    if (key === 'enter' && submitted ) {
      const answerToSend = [{ question_id, value: submitted.answer_id }];
      props.functionsBag.callbackFromQestion(answerToSend);
      setSubmitted(false);
    }
  }

  useEffect(() => {
    if (question.startTimer) {
      props.functionsBag.startTimer(timelimit_seconds, modulNumber);
    }
    return () => {
      if (question.finshTimer) {
        props.functionsBag.finshTimer(timelimit_seconds, modulNumber);
      }
    };
  }, [] );

  return(<div>
    <Header questionNumber={questionNumber}
      questionsLength={questionsLength} modulNumber={modulNumber}
      modulsLength={modulsLength} logo_url={logo_url} title={title}
      allow_later={allow_later} allow_dummydata={allow_dummydata} />
    <main className="maincontent sc_scale">
      <form onSubmit={e => e.preventDefault() }>
        <p className="question">{question.text}</p>
        <div className="answers">
          {divsInPage}
        </div>
        <div className="submit_container">
          {allow_back === true ?
            <input
              style={{ 'marginRight': '20px' }}
              disabled={question.firstQ=== true ? true : false }
              className={question.firstQ=== true ? 'inactive' : 'active' }
              type="button" name="commit" value={props.t('Back')}
              action="answer" id="button"
              onClick={() => props.functionsBag.stepBack()} />  : ''
          }
          <input disabled={ (typeof answer !== "undefined" || submitted ? false : true) }
            style={{ 'marginRight': '20px' }}
            type="button" name="commit" value={props.t('Continue')}
            className={ typeof answer !== "undefined" || submitted ? 'active' : 'inactive' }
            action="answer" id="button" data-disable-with="Create Given answer"
            onClick={handleSubmit} />
        </div>
      </form>
    </main>
    <KeyboardEventHandler
      handleKeys={['all']}
      onKeyEvent={ key => presskey(key)}
    />
  </div>
  );
};

export default withNamespaces()(ScScale);
