/* eslint-disable array-callback-return */
import React from 'react';
import Markdown from 'react-markdown';
export const letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

export function getValueFromKeyboard(key, props, setSubmitted, callbackFromQestion ) {  
  const toUpperCase = key.toUpperCase();   
  const findNode = document.getElementById(toUpperCase);
  if (findNode) {
    setSubmitted(parseInt(findNode.value, 10));
  }
  if (key === 'enter') {    
    callContent(props, callbackFromQestion);        
  }
}

// DOKU: Prepares answer Html for some question types
export function extractAnswer(question, setSubmitted, submitted, answer_parameter) {  
  const Answers = [];
  const answerText = [];
  const answerValue = [];
  const answerParameterId = answer_parameter? answer_parameter[0].answer_id : undefined
  question.answers.map( answer => {     
    answerText.push(answer.text);  
    answerValue.push(answer.id);
  });    
  
  answerText.map( (answer,i) => {
    let checked = false;
    if(submitted !== false){
      checked = submitted === answerValue[answerText.indexOf(answer)];
    } else {
      checked = answerParameterId === answerValue[answerText.indexOf(answer)];
    }

    Answers.push(        
      <div className="singleanswer" key={i} > 
        <input type="radio"
          value={answerValue[answerText.indexOf(answer)]} 
          name="given_answer" 
          id={letters[answerText.indexOf(answer)]} 
          onChange={() => setSubmitted(answerValue[answerText.indexOf(answer)])} 
          checked={checked} 
        />
        <label htmlFor={letters[answerText.indexOf(answer)]} >
          <span className="accesskey">{letters[answerText.indexOf(answer)]}</span>
          <Markdown source={answer} />  
        </label>
      </div>
    );
  });
  
  return Answers;
};

// DOKU: Is an onSubmit method to some of the question types
export function callContent(props, callbackFromQestion) {     
  const [question_id, submitted, answer]  = props;
  let toSubmit = submitted;
  if (!toSubmit) {
    toSubmit = answer[0].answer_id;
  }
  if (toSubmit) {
    callbackFromQestion( [{ question_id: question_id, answer_id: toSubmit, value : []}] ); 
  }
}

