
import React , { useState } from 'react';
import { useSelector } from 'react-redux';
import  Header   from '../Header';
import { API_ROOT } from '../../api-config';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import { ParseMarkdown } from '../../lib/ParseMarkdown';
import KeyboardEventHandler from 'react-keyboard-event-handler';



const NcGoto = props => {

  const [error, setError] = useState();
  const { token } = useSelector(state => state.tokenReducer);
  const { question , questionsLength , questionNumber, modulsLength, modulNumber,
    logo_url, allow_later, allow_dummydata, title } = props.bag;

  function getNextQuestionId() {
    axios({
      method: 'post',
      url: `${API_ROOT}/reactapi/getjump`,
      data: {
        token,
        question_id: question.id }
    }).then(
      response => props.functionsBag.callbackFromJumpQuestion(response.data.target_question),
    ).catch( () => {
      setError(true);
    });
  };
  if (question.text.length  < 1) {
    getNextQuestionId();
  }

  return <div>
    <Header questionNumber={questionNumber}
      questionsLength={questionsLength} modulNumber={modulNumber}
      modulsLength={modulsLength} logo_url={logo_url} title={title}
      allow_later={allow_later} allow_dummydata={allow_dummydata} />
    <div className={error ? 'error' : 'hidden'} >
      es ist ein interner Fehler aufgetreten
    </div>
    <ParseMarkdown source={question} />
    <div className="submit_container">
      <input
        style={{ 'marginRight': '20px' }}
        type="button" name="commit" value={props.t('Continue')}
        className='active' action="answer" id="button" data-disable-with="Create Given answer"
        onClick={() => getNextQuestionId() } />
    </div>
    <KeyboardEventHandler
      handleKeys={['enter']}
      onKeyEvent={ () => getNextQuestionId() }
    />
  </div>;
};
export default withNamespaces()(NcGoto);
