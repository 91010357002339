import React, { Component } from 'react';
import Header from  '../Header';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { withNamespaces } from 'react-i18next';
import Markdown from 'react-markdown'
import { ParseMarkdown } from '../../lib/ParseMarkdown';

class ScMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submittedAnswers: [],
      rows: [],
      column: [],
      active: false
    };
  }

  componentDidMount() {
    const rows = [];
    const column = [];
    const submittedAnswers = [];
    this.props.bag.question.answers.map(i =>
      rows.push(i)
    );
    this.props.bag.question.scales.map(i =>
      column.push(i.text)
    );

    this.props.bag.values.map(A =>
      A.map(i =>
        i.question_id === this.props.bag.question.id ? submittedAnswers.push([i]) : false
      )
    );

    this.setState({ rows , column, submittedAnswers });

    if (this.props.bag.question.startTimer) {
      this.props.functionsBag.startTimer(this.props.bag.timelimit_seconds, this.props.bag.modulNumber);
    }
    if (submittedAnswers.length === rows.length) {
      this.setState({ active: true });
    }
  }

  onChange = event => {
    const answersInState = this.state.submittedAnswers;
    const  question_id  = this.props.bag.question.id;
    const { value, name } = event.target;
    const answer_id = parseInt(name, 10);
    const answer = [{ question_id, answer_id, value }];
    // DOKU: wenn es answers gibt
    if (answersInState.length > 0) {
      // DOKU: iteriere über alle answers im state und führe für die die es bereits gibt deleteFromSubmittedAnswers() aus
      // eslint-disable-next-line array-callback-return
      answersInState.map( i => {
        if (i[0].answer_id === answer_id ) {
          deleteFromSubmittedAnswers(i[0]);
        }
      });
    }
    // DOKU: Löscht die answer die übergeben wird aus dem answer array im state
    function deleteFromSubmittedAnswers(answer) {
      const a = answersInState.findIndex(obj => obj[0].answer_id === answer.answer_id);
      answersInState.splice(a,1);
    }
    answersInState.push(answer);
    this.setState({
      submittedAnswers: answersInState
    });
    if (this.state.submittedAnswers.length === this.state.rows.length) {
      this.setState({ active: true });
    }
  }

  handleSubmit = () => {
    const { submittedAnswers } = this.state;
    const Answers = [];
    submittedAnswers.map(obj => Answers.push(obj[0])
    );

    this.props.functionsBag.callbackFromQestion(Answers);
  };

  presskey = key => {
    if (key === 'enter' && this.state.active === true) {
      const { submittedAnswers } = this.state;
      const Answers = [];
      submittedAnswers.map(obj => Answers.push(obj[0])
      );
      this.props.functionsBag.callbackFromQestion(Answers);
    }
  }

  componentWillUnmount() {
    if (this.props.bag.question.finshTimer) {
      this.props.functionsBag.finshTimer(this.props.bag.timelimit_seconds, this.props.bag.modulNumber);
    }
  }

  render() {
    const { question , questionsLength , questionNumber, modulsLength, modulNumber,
      logo_url, allow_back, allow_later, allow_dummydata, title } = this.props.bag;

    const answersInState = this.state.submittedAnswers;

    return(
      <main className="maincontent sc_matrix">
        <Header questionNumber={questionNumber}
          questionsLength={questionsLength} modulNumber={modulNumber}
          modulsLength={modulsLength} logo_url={logo_url} title={title}
          allow_later={allow_later} allow_dummydata={allow_dummydata}  />
        <form onSubmit={e =>  e.preventDefault() }>
          <div className="question"><ParseMarkdown source={question} /></div>
          <div className="answers">
            <table>
              <thead>
                <tr>
                  <th></th>
                  {
                    this.state.column.map(
                      (answer, i) => <th key={i}><Markdown source={answer} /></th>
                    )
                  }
                </tr>
              </thead>
              <tbody>
                {
                  this.state.rows.map((answer, i) =>
                    <tr key={i}>
                      <th><ParseMarkdown source={answer} /></th>
                      {this.state.column.map(
                        (value, d) => <td  key={d}> <input type="radio" value={value} id={d} name={answer.id} checked={typeof answersInState.find(a => typeof a[0] !== 'undefined' && a[0].answer_id === answer.id && a[0].value === value) !== "undefined"}
                          onChange={ event => this.onChange(event) } /></td>
                      )}
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
          <div className="submit_container">
            {allow_back === true ?
              <input
                style={{ 'marginRight': '20px' }}
                disabled={question.firstQ=== true ? true : false }
                className={question.firstQ=== true? 'inactive' : 'active' }
                type="button" name="commit" value={this.props.t('Back')}
                action="answer" id="button"
                onClick={() => this.props.functionsBag.stepBack()} />  : ''
            }
            <input
              disabled={this.state.active === true? false: true}
              style={{ 'marginRight': '20px' }}
              type="button" name="commit" value={this.props.t('Continue')}
              className={this.state.active? 'active' : 'inactive' }
              action="answer" id="button" data-disable-with="Create Given answer"
              onClick={this.handleSubmit} />
            <span className="enter" style={{ display: 'none' }}>mit Enter</span>
          </div>
          <KeyboardEventHandler
            handleKeys={['all']}
            onKeyEvent={ key => this.presskey(key)}
          />
        </form>
      </main>

    );
  }
};
export default withNamespaces()(ScMatrix);
