
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { extractAnswer } from '../../privateFunctions';
import  Header   from '../Header';
import { API_ROOT } from '../../api-config';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { ParseMarkdown } from '../../lib/ParseMarkdown';

const ScGoto = props => {
  const [error, setError] = useState();
  const { token } = useSelector(state => state.tokenReducer);
  const question_id = props.bag.question.id;
  const [submitted, setSubmitted] = useState(false);
  const { question , questionsLength , questionNumber, modulsLength, modulNumber,
    logo_url, allow_later, allow_dummydata, title } = props.bag;

  function getNextQuestionId() {
    axios({
      method: 'post',
      url: `${API_ROOT}/reactapi/getjump`,
      data: {
        token,
        question_id,
        answer_id: submitted }
    }).then(
      response => props.functionsBag.callbackFromJumpQuestion(response.data.target_question),
    ).catch( error => {
      setError(true);
    });
  };

  function getValueFromKeyboard(key) {
    const toUpperCase = key.toUpperCase();
    const findNode = document.getElementById(toUpperCase);
    if (findNode) {
      setSubmitted(parseInt(findNode.value, 10));
    }
    if (key === 'enter' && submitted) {
      getNextQuestionId();
    }
  }

  return(
    <div>
      <Header questionNumber={questionNumber}
        questionsLength={questionsLength} modulNumber={modulNumber}
        modulsLength={modulsLength} logo_url={logo_url} title={title}
        allow_later={allow_later} allow_dummydata={allow_dummydata} />
      <main className="maincontent sc_yesno" id='main'>
        <div className={error ? 'error' : 'hidden'} >
           es ist ein interner Fehler aufgetreten
        </div>
        <form onSubmit={e =>  e.preventDefault() }>
          <div className="question"><ParseMarkdown source={question} /></div>
          <div className="answers">
            {extractAnswer(question, setSubmitted, submitted)}
          </div>
          <div className="submit_container">
            <input disabled={submitted? false : true }
              style={{ 'marginRight': '20px' }}
              type="button" name="commit" value={props.t('Continue')} className={submitted? 'active' : 'inactive' }
              action="answer" id="button" data-disable-with="Create Given answer"
              onClick={() => getNextQuestionId() } />
          </div>
          <KeyboardEventHandler
            handleKeys={['all']}
            onKeyEvent={ key => getValueFromKeyboard(key) }
          />
        </form>
      </main>
    </div>
  );
};
export default withNamespaces()(ScGoto);