/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { ParseMarkdown } from '../lib/ParseMarkdown';

class Report extends Component {

    state = {
      email: ''
    }

    componentDidMount() {
      if (this.props.personal_data) {
        const { email } = this.props.personal_data;
        if (email) {
          // eslint-disable-next-line prefer-destructuring
          let value = email[0];
          if (value === null ) {
            value = '';
          }
          this.setState({ email : value });
        }
      }
    }

    handleChange = event => {
      const { value } = event.target;
      let { email } = this.state;
      email = value;
      this.setState({ email });
    }

    handleSubmit = event => {
      this.props.callbackFromReport(this.state.email);
      if (event) {
        event.preventDefault();
      }
    }

    render() {
      let src;
      this.props.getreport.length > 0 ? src = this.props.getreport[0] : src = 'please insert your Email to get a report';
      return(<main className="maincontent textpage">
        <div className="data">
          <ParseMarkdown source={src} />
          <form  onSubmit={this.handleSubmit} id='form'>
            <label key='key' style={{ textAlign: 'center' }}>
              {this.props.t('email')}
              <br /></label>
            <input
              style={{ textTransform: 'lowercase', display: 'block', margin: 'auto' }}
              value={this.state.email}
              name='email'
              type='email'
              size="30"
              pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$"
              onChange={ event => this.handleChange(event) }
            />
            <div className="submit_container">
              <input type="button" name="commit"  value={this.props.t('Continue')}
                className="active" action="answer" id="button" data-disable-with="Create Given answer"
                onClick={ () => this.handleSubmit() }
              />
            </div>
            <KeyboardEventHandler
              handleKeys={['enter']}
              onKeyEvent={ () => this.handleSubmit() }
            />
          </form>
        </div>
      </main>);
    }
}

const mapStateToProps = state => {
  const { token } = state.tokenReducer;
  const { personal_data, getreport } = state.rootReducer.data;
  return {
    personal_data, getreport, token
  };
};

export default connect(mapStateToProps)(withNamespaces()(Report));