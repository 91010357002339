import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
import { rootReducer, appReducer, headerReducer, tokenReducer, dummyReducer } from './reduxReducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const Logger = store => next => action => {
  next(action);
};

export const store =  createStore(
  combineReducers({
    rootReducer,
    appReducer, 
    headerReducer,
    tokenReducer,
    dummyReducer
  }),
  {},
  composeEnhancers(applyMiddleware(Logger))
);
