/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { letters } from '../../privateFunctions';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import  Header   from '../Header';
import { withNamespaces } from 'react-i18next';
import { ParseMarkdown } from '../../lib/ParseMarkdown';



class McSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: undefined,
      Answers: [],
      submitted: [],
      question_id: null,
      text: [],
      value: [],
    };
  }

  componentDidMount() {
    const question_id = this.props.bag.question.id;
    const text = [];
    const value = [];
    const answers = this.props.bag.values;
    let propAnswer = answers.find(v => v[0].question_id === this.props.bag.question.id);
    this.props.bag.question.answers.map( answer => {
      text.push(<ParseMarkdown source={answer} />);
      value.push(answer.id);
    });
    this.setState({ text: text, value: value });
    let submitted = [];
    if(typeof propAnswer !== "undefined") {
      this.setState({ answer: propAnswer })
      propAnswer.map( a => {
        submitted.push(a.answer_id);
      });
    }
    this.setState({ submitted: submitted, question_id: question_id });
  }


  presskey = key => {
    const toUpperCase = key.toUpperCase();
    const findNode = document.getElementById(toUpperCase);
    const { submitted } = this.state;
    if (key === 'enter' && this.state.submitted.length > 0 ) {
      const { question_id } = this.state;
      const Answer = [];
      submitted.map(answer => Answer.push({ question_id, answer_id: answer, value: []}) );
      this.setState({ submitted: []});
      this.props.functionsBag.callbackFromQestion(Answer) ;
      var x = document.getElementsByName('given_answer');
      for (let index = 0; index < x.length; index++) {
        x[index].checked = false;
      }
    }

    if (findNode) {
      const elemnt = parseInt(findNode.value, 10 );
      if (submitted.includes(elemnt)) {
        submitted.splice(submitted.indexOf(elemnt), 1);
        findNode.checked = false;
      } else {
        submitted.push(elemnt);
        findNode.checked = true;
      }
      this.setState({ submitted });
    }
  }

  setSubmitted = event => {
    const elemnt = parseInt(event.target.value);
    const { submitted } = this.state;
    if (submitted.includes(elemnt)) {
      submitted.splice(submitted.indexOf(elemnt), 1);
    } else {
      submitted.push(elemnt);
    }
    this.setState({
      submitted
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const { submitted } = this.state;
    const { question_id } = this.state;
    const Answer = [];
    submitted.map(answer => Answer.push({ question_id: question_id, answer_id: answer, value: []}) )  ;
    this.props.functionsBag.callbackFromQestion(Answer);
    var x = document.getElementsByName('given_answer');
    for (let index = 0; index < x.length; index++) {
      x[index].checked = false;
    }
  };

  componentWillUnmount() {
    if (this.props.bag.question.finshTimer) {
      this.props.functionsBag.finshTimer(this.props.bag.timelimit_seconds, this.props.bag.modulNumber);
    }
  }

  render() {
    const { question , questionsLength , questionNumber, modulsLength, modulNumber,
      logo_url, allow_back, allow_later, allow_dummydata, title } = this.props.bag;

    const propAnswer = this.state.answer
    const text = this.state.text;
    const value = this.state.value;
    let disabled = true;
    if (this.state.submitted.length === 0) {
      disabled = true
    } else {
      if (this.state.submitted.length > 0 || propAnswer !== "undefined") {
        disabled = false;
      }
    }

    return(
      <div>
        <Header questionNumber={questionNumber}
          questionsLength={questionsLength} modulNumber={modulNumber}
          modulsLength={modulsLength} logo_url={logo_url} title={title}
          allow_later={allow_later} allow_dummydata={allow_dummydata} />
        <main className="maincontent mc_simple">
          <span className="question"><ParseMarkdown source={question} /></span>
          <p className="questionhint">{this.props.t('Multiple selection possible')}</p>
          <div className="answers">
            <form onSubmit={e => this.handleSubmit(e)}>
              {text.map( (answer,i) => ( 
                <div className="singleanswer" key={i} >
                  <input type="checkbox"
                    value={value[text.indexOf(answer)]}
                    name="given_answer"
                    id={letters[text.indexOf(answer)]}
                    onChange={ this.setSubmitted }
                    checked={ typeof this.state.submitted.find(element => element === value[i]) !== "undefined" }
                  />
                  <label htmlFor={letters[text.indexOf(answer)]} >
                    <span className="accesskey">{letters[text.indexOf(answer)]}</span>
                    {answer}
                  </label>
                </div>
              ))}
              <div className="submit_container">
                {allow_back === true ?
                  <input
                    style={{ 'marginRight': '20px' }}
                    disabled={question.firstQ=== true ? true : false }
                    className={question.firstQ=== true? 'inactive' : 'active' }
                    type="button" name="commit" value={this.props.t('Back')}
                    action="answer" id="button"
                    onClick={() => this.props.functionsBag.stepBack()} 
                  />  : ''
                }
                <input disabled={ disabled } type="button" name="commit" value={this.props.t('Continue')}
                  className={ disabled ? 'inactive' : 'active' }
                  action="answer" id="button" data-disable-with="Create Given answer"
                  onClick={ e => this.handleSubmit(e) }
                />
                <span className="enter" style={{ display: 'none' }}>mit Enter</span>
              </div>
            </form>
          </div>
          <KeyboardEventHandler
            handleKeys={['all']}
            onKeyEvent={ key => this.presskey(key)}
          />

        </main>
      </div>
    );
  }
}

export default withNamespaces()(McSimple) ;
