import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import translationEN from './languages/en.json';
import translationDE from './languages/de.json';
import translationAL from './languages/al.json';
import translationBG from './languages/bg.json';
import translationBS from './languages/bs.json';
import translationCN from './languages/cn.json';
import translationCR from './languages/cr.json';
import translationCS from './languages/cs.json';
import translationES from './languages/es.json';
import translationFR from './languages/fr.json';
import translationGR from './languages/gr.json';
import translationHU from './languages/hu.json';
import translationIT from './languages/it.json';
import translationJP from './languages/jp.json';
import translationMK from './languages/mk.json';
import translationPL from './languages/pl.json';
import translationRO from './languages/ro.json';
import translationRU from './languages/ru.json';
import translationSE from './languages/se.json';
import translationSR from './languages/sr.json';

// the translations
const resources = {
  al: {
    translation: translationAL
  },
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  bg: {
    translation: translationBG
  },
  bs: {
    translation: translationBS
  },
  cn: {
    translation: translationCN
  },
  cr: {
    translation: translationCR
  },
  cs: {
    translation: translationCS
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  },
  gr: {
    translation: translationGR
  },
  hu: {
    translation: translationHU
  },
  it: {
    translation: translationIT
  },
  jp: {
    translation: translationJP
  },
  mk: {
    translation: translationMK
  },
  pl: {
    translation: translationPL
  },
  ro: {
    translation: translationRO
  },
  ru: {
    translation: translationRU
  },
  se: {
    translation: translationSE
  },
  sr: {
    translation: translationSR
  }
};


i18n
  .use(reactI18nextModule)
  .init({
    resources,
    lng: 'en',

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
