import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import Home from './Components/Home';
import Login from './Components/Login';
import Success from './Components/Success';
import { Route, Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { store } from './reduxStore';
import ContinueLater from './Components/ContinueLater';
import DummyData from './Components/DummyData';
import './i18n';

export const history = createBrowserHistory();

const Routing = () => (
  <Router history={history}>
    <Route path="/app" component={App} />
    <Route path="/home/" component={Home} />
    <Route path="/success" component={Success} />
    <Route path="/later" component={ContinueLater} />
    <Route path="/dummy" component={DummyData} />
    <Route exact path="/" component={Login} />
  </Router>
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routing>
        <App store={store} />
      </Routing>
    </BrowserRouter>
  </Provider>, document.getElementById('root'));

serviceWorker.unregister();
