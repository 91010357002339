import React , { Component } from 'react';
import { connect } from 'react-redux';
import '../App.css';

class Footer extends Component {
  render() {
    return(
      <footer>
        <div className="logomandant">
          {this.props.logo_url? <img src={this.props.logo_url}  alt="Logo Fragebogen"  title="CAPTain" />: ''} 
        </div>
        <div className="cntbrand">
          <p className="brandheadline">CNT-Testcenter</p>
          <p className="copyright">© CNT Gesellschaft für Personal- und <br />Unternehmensentwicklung mbH</p>
        </div>
      </footer>
    );
  }
}


const mapStateToProps = state => {
  const { logo_url } = state.rootReducer.data;
  return {
    logo_url
  };  
};

export default connect(mapStateToProps)(Footer);
