/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
import React  from 'react';
import { useSelector } from 'react-redux';
import '../App.css';
import Footer from './Footer';
import { withNamespaces } from 'react-i18next';
import { API_ROOT } from '../api-config';
import axios from 'axios';
import { ParseMarkdown } from '../lib/ParseMarkdown';

const  DummyData = () => {
  const { token } = useSelector(state => state.tokenReducer);
  const { dummy } = useSelector(state => state.dummyReducer);
  const { end } = useSelector(state => state.rootReducer.data);
  const answers = [];

  if (dummy) {
    dummy.map(a => {
      if (a.kind === 'sc_matrix') {
        const question_id = a.id;
        const answerId = [];
        const value = [];
        const randonAnswerId = a.answers[Math.floor(Math.random() * a.answers.length)];
        answerId.push(randonAnswerId.id);
        const Answer = { question_id, answer_id: answerId[0], value: value[0] };
        answers.push(Answer);
      } else if(a.kind === 'scale') {
        const question_id = a.id;
        const value = Math.floor(Math.random() * 11);
        const Answer = { question_id, value };
        answers.push(Answer);
      } else if(a.kind === 'freetext') {
        answers.push({ question_id: a.id, answer_id: [], value: a.text });
      }  else {
        const answerId = a.answers[Math.floor(Math.random() * a.answers.length)];
        answers.push({ question_id: a.id, answer_id: answerId.id , value: []});
      }
    });
  }

  function callBackend() {
    axios({
      method: 'post',
      url: `${API_ROOT}/reactapi/setdata`,
      data: {
        token: token,
        answers,
        dummy_data: true,
        isLast: true
      }
    });
  }
  callBackend();
  let src;
  end? src = end[0] : src = 'Finished';

  return(
    <div id='contentwrapper'>
      <main>
        <div className='maincontent success'>
          <ParseMarkdown source={src} />
        </div>
      </main>
      <Footer />
    </div>
  );
};




export default withNamespaces()(DummyData);
