/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { extractAnswer, getValueFromKeyboard, callContent } from '../../privateFunctions';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import  Header   from '../Header';
import { withNamespaces } from 'react-i18next';
import { ParseMarkdown } from '../../lib/ParseMarkdown';

const ScSimple = props => {
  const [submitted, setSubmitted] = useState(false);
  const question_id = props.bag.question.id;
  const { question , questionsLength , questionNumber, modulsLength, modulNumber,
    logo_url, allow_back, allow_later, allow_dummydata, title, timelimit_seconds } = props.bag;
  useEffect(() => {
    if (question.startTimer) {
      props.functionsBag.startTimer(timelimit_seconds, modulNumber);
    }
    return () => {
      if (question.finshTimer) {
        props.functionsBag.finshTimer(timelimit_seconds, modulNumber);
      }
    };
  }, [] );
  
  const answers = props.bag.values;
  let answer = answers.find(v => v[0].question_id === props.bag.question.id);
  let disabled = true;
  if(typeof answer !== "undefined" || submitted){
    disabled = false;
  }
  
  return(
    <div>
      <Header questionNumber={questionNumber}
        questionsLength={questionsLength} modulNumber={modulNumber}
        modulsLength={modulsLength} logo_url={logo_url} title={title}
        allow_later={allow_later} allow_dummydata={allow_dummydata} />
      <main id='main'>
        <form onSubmit={e =>  e.preventDefault() }>
          <div className="question"><ParseMarkdown source={question} /></div>
          <div className="answers">
            {extractAnswer(question, setSubmitted, submitted, answer)}
          </div>
          <div className="submit_container">
            {allow_back === true ?
              <input
                disabled={question.firstQ=== true ? true : false }
                className={question.firstQ=== true? 'inactive' : 'active' }
                type="button" name="commit" value={props.t('Back')}
                action="answer" id="button"
                onClick={() => props.functionsBag.stepBack()} 
              />  : ''
            }
            <input disabled={ disabled }
              style={{ 'marginRight': '20px' }}
              type="button" name="commit" value={props.t('Continue')}
              className={disabled? 'inactive' : 'active' }
              action="answer" id="button" data-disable-with="Create Given answer"
              onClick={() => callContent([question_id, submitted, answer, []], props.functionsBag.callbackFromQestion, setSubmitted) } 
            />
          </div>
          <KeyboardEventHandler
            handleKeys={['all']}
            onKeyEvent={key => getValueFromKeyboard(key, [question_id, submitted, []], setSubmitted, props.functionsBag.callbackFromQestion) }
          />
        </form>
      </main>
    </div>
  );
};

export default withNamespaces()(ScSimple);
