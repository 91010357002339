/* eslint-disable prefer-destructuring */
import React , { Component } from 'react';
import '../App.css';
import Markdown from 'react-markdown';
import { withNamespaces } from 'react-i18next';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { ParseMarkdown } from '../lib/ParseMarkdown';


class Begin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isPrivacy: false,
      showPrivacy: false,
      showCheckbox: false
    };
  }

  componentDidMount() {
    if (this.props.begin[0].popup.length > 0 ) {
      this.setState({
        isPrivacy: true
      });
    }
    if (this.props.begin[0].checkbox.length > 0 ) {
      this.setState({
        showCheckbox: true
      });
    } else {
      this.setState({
        submitted: true
      });
    }
  }

  clickEnter = () => {
    if (this.state.submitted === true) {
      this.props.callbackFromBegin();
    }
  };

  render() {
    const { popword, checkbox, popup } = this.props.begin[0];
    const { submitted, isPrivacy, showPrivacy, showCheckbox } = this.state;
    const source = this.props.begin[0];

    return(
      <div>
        <header>
          <div className="questionnaire-brand" >
            <span className="questionnaire-name">{this.props.t('CNT-Testcenter')} </span>
          </div>
        </header>
        <main className="maincontent textpage" >
          <ParseMarkdown source={source} />
          <div className={isPrivacy? '' : 'hidden'}><div className='divHover' onClick={ () => this.setState({ showPrivacy: !showPrivacy }) }>{popword[0]}</div></div>
          <div style={{ 'marginBottom' : '20px' }} className={showPrivacy? '' : 'hidden'} >
            <Markdown source={popup[0]} />
          </div>
          <div className={showCheckbox? 'pleaseagree' : 'hidden' } >
            <input type="checkbox"  id="Datenschutz" onChange={() => this.setState({ submitted: !submitted })} />
            <label htmlFor="Datenschutz">{checkbox}</label>
          </div>
          <div className="submit_container">
            <input disabled={submitted? false : true }
              type="button" name="commit" value={this.props.t('Continue')}
              className={submitted? 'active' : 'inactive' }
              action="answer" id="button" data-disable-with="Create Given answer"
              onClick={() => this.props.callbackFromBegin()}
            />
          </div>
        </main>
        <KeyboardEventHandler
          handleKeys={['enter']}
          onKeyEvent={key => this.clickEnter(key)}
        />
      </div>
    );
  }


}

export default withNamespaces()(Begin);
