import React, { Component } from 'react';
import { connect } from 'react-redux';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>{this.props.error[0].text}</h1>;
    }
    return this.props.children;
  }
}
const mapStateToProps = state => {
  const { error } = state.rootReducer.data;
  return { error };
};
export default connect(mapStateToProps)(ErrorBoundary);
