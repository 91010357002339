import React  from 'react';
import { useSelector } from 'react-redux';
import '../App.css';
import Footer from './Footer';
import { withNamespaces } from 'react-i18next';
import { API_ROOT } from '../api-config';
import axios from 'axios';
import { ParseMarkdown } from '../lib/ParseMarkdown';


const  ContinueLater = props => {
  const { later } = useSelector(state => state.rootReducer.data);
  const { logo_url } = useSelector(state => state.rootReducer.data);
  const { token } = useSelector(state => state.tokenReducer);
  const Text = later? later[0] : 'Please Sign in first';

  function callBackend() {
    axios({
      method: 'post',
      url: `${API_ROOT}/reactapi/setdata`,
      data: {
        token: token,
        continue_later: true
      }
    });
  }
  callBackend();

  return(
    <div id="contentwrapper">
      <header>
        <div className="questionnaire-brand" >
          <span className="questionnaire-name">{props.t('CNT-Testcenter')}</span>
        </div>
      </header>
      <ParseMarkdown source={Text} />
      <Footer  logo_url={logo_url} />
    </div>
  );
};




export default withNamespaces()(ContinueLater);
