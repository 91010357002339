import React, { Component } from 'react';
import  Header   from '../Header';
import { withNamespaces } from 'react-i18next';
import { ParseMarkdown } from '../../lib/ParseMarkdown';

class FreeText extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    if (this.props.bag.question.startTimer) {
      this.props.functionsBag.startTimer(this.props.bag.timelimit_seconds, this.props.bag.modulNumber);
    }
    const answers = this.props.bag.values;
    let ret = answers.find(v => v[0].question_id === this.props.bag.question.id);
    const value = this.displayValue(ret)
    this.setState({ text: value })
  }


  handleSubmit = event => {
    const question_id = this.props.bag.question.id;
    const { text } = this.state;
    const answer = [{ question_id, answer_id: [], value: text }];
    event.preventDefault();
    if (text.length > 0) {
      this.props.functionsBag.callbackFromQestion(answer);
      this.setState({ text: '' });
    }
  };

  handleChange(event) {
    const answers = this.props.bag.values;
    let ret = answers.find(v => v[0].question_id === this.props.bag.question.id);
    let value = event.target.value;
    if(ret !== null && typeof ret !== 'undefined') {
      ret[0]['value'] = value;
    }

    this.setState({ text: value });
  }

  componentWillUnmount() {
    if (this.props.bag.question.finshTimer) {
      this.props.functionsBag.finshTimer(this.props.bag.timelimit_seconds, this.props.bag.modulNumber);
    }
  }

  // DOKU: Prepares answer value for the Html
  displayValue(answer) {
    let ret = this.state.text
    if((answer !== null && typeof answer!== 'undefined') && typeof this.state.text == 'undefined') {
      ret = answer[0].value;
    }

    return ret
  }

  render() {
    const { question , questionsLength , questionNumber, modulsLength, modulNumber,
      logo_url, allow_back, allow_later, allow_dummydata, title } = this.props.bag;
      
    return(
      <div>
        <Header questionNumber={questionNumber}
          questionsLength={questionsLength} modulNumber={modulNumber}
          modulsLength={modulsLength} logo_url={logo_url} title={title}
          allow_later={allow_later} allow_dummydata={allow_dummydata} />
        <main className="maincontent freetext">
          <div className="question">
            <ParseMarkdown source={question} />
          </div>
          <p className="questionhint">{this.props.t('Max 1000 letters')}</p>
          <div className="answers">
            <div className="singleanswer">
              <form onSubmit={e => this.handleSubmit(e)}>
                <textarea value={ this.state.text } onChange={ e => this.handleChange(e) } autoFocus />
                <div className="submit_container">
                  {allow_back === true ?
                    <input
                      style={{ 'marginRight': '20px' }}
                      disabled={ question.firstQ === true ? true : false }
                      className={ question.firstQ === true ? 'inactive' : 'active' }
                      type="button" name="commit" value={this.props.t('Back')}
                      action="answer" id="button"
                      onClick={() => this.props.functionsBag.stepBack()} />  : ''
                  }
                  <input type="button" name="commit" value={this.props.t('Continue')}
                    className={this.state.text ? 'active' : 'inactive' }
                    action="answer" id="button" data-disable-with="Create Given answer"
                    onClick={ e => this.handleSubmit(e) }
                  />
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    );
  }
};
export default withNamespaces()(FreeText) ;
