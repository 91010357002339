import { SET_DATA, SET_HEADER, SET_LOADED, SET_TOKEN, SET_DUMMY, UPDATA_DUMMY, UPDATE_PERSONAL_DATA } from './reduxActions';

const initialState = {
  data: []
};

const headerState = {
  headerData: []
};


export const rootReducer = (state = initialState, action) => {  
  switch (action.type) {
  case SET_DATA:
    return {
      data: action.payload
    };
  case UPDATE_PERSONAL_DATA:
    const { data } = state;
    data.personal_data = action.payload;    
    return {
      ...state, data
    };
  default:
    return state;
  }
};

export const appReducer = (state = false, action) => {
  switch (action.type) {
  case SET_LOADED:
    return {
      loaded: action.payload
    };
  default:
    return state;
  }
};

export const headerReducer = (state = headerState, action) => {
  switch (action.type) {
  case SET_HEADER:
    return {
      data: action.payload
    };
  default:
    return state;
  }
};

export const tokenReducer = (state = '', action) => {
  switch (action.type) {
  case SET_TOKEN:
    return {
      token: action.payload
    };
  default:
    return state;
  }
};

export const dummyReducer = (state = [], action) => {  
  switch (action.type) {
  case SET_DUMMY:
    return {
      dummy: action.payload
    };
  case UPDATA_DUMMY:
    return {
      ...state,
      dummy: action.payload
    };
  default:
    return state;
  }
};




