import React, { Component } from 'react';
import Begin from './Begin';
import PersonalData from './PersonalData';
import Report from './Report';
import Content from './Content';
import Footer from './Footer';
import { connect } from 'react-redux';
import { API_ROOT } from '../api-config';
import axios from 'axios';
import ErrorBoundary from './ErrorBoundary';
import { updatePersonalData } from '../reduxActions';


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Step: 0,
      serverError: false
    };
  }

  //begin => personaldata => report => questions

  componentDidMount() {
    if (this.props.begin.length > 0) {
      this.setState({ Step: 1 });
    } else if (this.props.personal_data.length > 0 ) {
      this.setState({ Step: 2 });
    } else if (this.props.getreport.length > 0) {
      this.setState({ Step: 3 });
    } else {
      this.setState({ Step: 4 });
    }

    if (this.props.company_color.length > 0 ) {
      document.documentElement.style
        .setProperty('--color-mandant', this.props.company_color);
    }
  }

  callbackFromBegin = () => {
    if (this.props.personal_data.length > 0) {
      this.setState({ Step: 2 });
    } else if (this.props.getreport.length > 0) {
      this.setState({ Step: 3 });
    } else {
      this.setState({ Step: 4 });
    }
  }

  callbackFromPersonalData = data => {
    this.props.updatePersonalData(data);
    const { token } = this.props;
    axios({
      method: 'post',
      url: `${API_ROOT}/reactapi/setdata`,
      data: {
        token: token,
        personal_data : data
      }
    }).then( ()  => {
      if (this.props.getreport.length > 0) {
        this.setState({ Step: 3 });
      } else {
        this.setState({ Step: 4 });
      }
    }
    ).catch( error => {
      this.setState({ serverError: true });
    });
  }

  callbackFromReport = data => {
    if (data.length > 0) {
      const { token } = this.props;
      axios({
        method: 'post',
        url: `${API_ROOT}/reactapi/setdata`,
        data: {
          token: token,
          report_email : data
        }
      }).then( ()  => {
        this.setState({ Step: 4 });
      }
      ).catch( error => {
        this.setState({ serverError: true });
      });
    } else {
      this.setState({ Step: 4 });
    }
  }

  render() {
    return(
      <ErrorBoundary>
        {this.state.Step === 1 ? <Begin callbackFromBegin={ this.callbackFromBegin } begin={this.props.begin} language={this.props.language} /> : ''}
        {this.state.Step === 2 ? <PersonalData callbackFromPersonalData={ this.callbackFromPersonalData } personal_data={ this.props.personal_data } /> : ''}
        {this.state.Step === 3 ? <Report callbackFromReport={ this.callbackFromReport } getreport={ this.props.getreport } /> : ''}
        {this.state.Step === 4 ?  <Content  /> : ''}
        <Footer  />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => {
  const { token } = state.tokenReducer;
  const { begin, personal_data, logo_url, company_color, language, getreport } = state.rootReducer.data;
  return {
    begin, personal_data, logo_url, company_color, language, getreport, token
  };
};

const mapDispatchToProps = dispatch => ({
  updatePersonalData: data => dispatch(updatePersonalData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
