/* eslint-disable prefer-destructuring */

import React , { Component } from 'react';
import '../App.css';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import Footer from './Footer';
import { ParseMarkdown } from '../lib/ParseMarkdown';

class Success extends Component {
  render() {
    let src;
    this.props.end? src = this.props.end[0] : src = 'Finished';
    return(
      <div id='contentwrapper'>
        <main>
          <div className='maincontent success'>
            <div>
              <ParseMarkdown source={src} />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { end } = state.rootReducer.data;
  return {
    end
  };
};

export default connect(mapStateToProps)(withNamespaces()(Success)) ;