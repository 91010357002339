import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import  Header   from '../Header';
import { ParseMarkdown } from '../../lib/ParseMarkdown';

class NumberScale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      answer: undefined,
      changed: false
    };
  }

  componentDidMount() {
    const answers = this.props.bag.values;
    let answer = answers.find(v => v[0].question_id === this.props.bag.question.id);
    if (typeof answer !== "undefined"){
      this.setState({ answer: answer });
      if (this.state.text === '') {
        this.setState({ text: answer[0].value })
      }
    }
    
    if (this.props.bag.question.startTimer) {
      this.props.functionsBag.startTimer(this.props.bag.timelimit_seconds, this.props.bag.modulNumber);
    }
  }

  handleChange = event => {
    this.setState({ text: event.target.value, changed: true });
  }

  handleSubmit = event => {
    event.preventDefault();
    const question_id = this.props.bag.question.id;
    const { text } = this.state;
    const answer = [{ question_id, answer_id: [], value: text }];
    if (text.length > 0) {
      this.props.functionsBag.callbackFromQestion(answer);
      this.setState({ text: '' });
    }
  };

  componentWillUnmount() {
    if (this.props.bag.question.finshTimer) {
      this.props.functionsBag.finshTimer(this.props.bag.timelimit_seconds, this.props.bag.modulNumber);
    }
  }


  render() {
    const { question , questionsLength , questionNumber, modulsLength, modulNumber,
      logo_url, allow_back, allow_later, allow_dummydata, title } = this.props.bag;

    let answer = this.state.answer
    let disabled = true;
    if (typeof answer !== "undefined" && this.state.text !== '') {
      disabled = false;
    }
    let value = this.state.text
    if(typeof answer !== "undefined" && (value === '' && this.state.changed === false)) {
      value = answer[0].value
    }

    return(<div>
      <Header questionNumber={questionNumber}
        questionsLength={questionsLength} modulNumber={modulNumber}
        modulsLength={modulsLength} logo_url={logo_url} title={title}
        allow_later={allow_later} allow_dummydata={allow_dummydata} />
      <main className="maincontent freetext">
        <div className="question"><ParseMarkdown source={question} /></div>
        <form onSubmit={e => this.handleSubmit(e)}>
          <div className="answers">
            <div className="numbaer">
              <input name='' type='number' maxLength="11"
                style={{ 'width': '6em', 'textAlign': 'right' }}
                value={ value }
                onChange={ e => this.handleChange(e) }
              />
            </div>
          </div>
          <div className="submit_container">
            {allow_back === true ?
              <input
                style={{ 'marginRight': '20px' }}
                disabled={question.firstQ=== true ? true : false }
                className={question.firstQ=== true? 'inactive' : 'active' }
                type="button" name="commit" value={this.props.t('Back')}
                action="answer" id="button"
                onClick={() => this.props.functionsBag.stepBack()} />  : ''
            }
            <input  type="button" name="commit" value={this.props.t('Continue')}
              className={this.state.text || !disabled? 'active' : 'inactive' }
              action="answer" id="button" data-disable-with="Create Given answer"
              onClick={ e => this.handleSubmit(e) }
            />
          </div>
        </form>
      </main>
    </div>);
  }
};
export default withNamespaces()(NumberScale);
