/* eslint-disable prefer-destructuring */
import React from 'react';
import Markdown from 'react-markdown';
import ReactPlayer from 'react-player/lazy';
import ReactAudioPlayer from 'react-audio-player';

// react-markdown dos not support Video and Audio
// it returns an img tag
//  this function Parse the props of markdown library to check the format in img tag
export const ParseMarkdown = props => {
  return <Markdown
    source={props.source.text}
    renderers={{ paragraph: checkProps }}
  />;
};

const checkProps = props => {
  const element = props.children[0];
  let newNode;
  if (element.type === 'img') {
    if (AudioList(element.props.src)) {
      newNode = <ReactAudioPlayer
        src={element.props.src}
        controls
      />;
    } else if(VideoList(element.props.src)) {
      newNode = <ReactPlayer url={element.props.src}  controls={true} />;
    } else newNode = { ...element };
  } else newNode = <p {...props} />;
  return newNode;
};


function AudioList(format) {
  const List = ['.wav', '.mp3', '.ogg', '.flac', '.aac', '.m4a'];
  let audio = false;
  List.map( ext => {
    if (format.includes(ext)) {
      audio = true;
    }
    return null;
  });
  return audio;
}

function VideoList(format) {
  const List = ['.mp4', '.m4v', '.avi', '.wmv', '.flv'];
  let video = false;
  List.map( ext => {
    if (format.includes(ext)) {
      video = true;
    }
    return null;
  });
  return video;
}
