/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
import React , { Component } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { withNamespaces } from 'react-i18next';


class PersonalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      formData: {}
    };
  }

  componentDidMount() {
    const formData = {};
    this.props.personal_data.map( input => {
      formData[input.field] = [input.value , input.required];
    }
    );
    this.setState({ formData });
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { formData } = this.state;
    // eslint-disable-next-line prefer-destructuring
    const a = formData[name][1];
    formData[name] = [value, a ];
    this.setState({ formData });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.callbackFromPersonalData(this.state.formData);
  }


  clickEnter = key => {
    if (key === 'enter') {
      this.props.callbackFromPersonalData(this.state.formData);
    }
  };

   TextField = (key, data) => {
     let value, labelText;
     data[0] ? value = data[0] : value = '';
     switch (key) {
     case 'forename' : labelText = this.props.t('forename') ; break;;
     case 'surname' : labelText = this.props.t('surname') ; break;;
     case 'pseudonym' : labelText = this.props.t('pseudonym') ; break;;
     case 'company' : labelText = this.props.t('company') ; break;;
     case 'street' : labelText = this.props.t('street') ; break;;
     case 'city' : labelText = this.props.t('city') ; break;;
     case 'job' : labelText = this.props.t('job') ; break;;
     case 'business' : labelText = this.props.t('business') ; break;;
     case 'position' : labelText = this.props.t('position') ; break;;
     case 'division' : labelText = this.props.t('division') ; break;;
     case 'phone' : labelText = this.props.t('phone') ; break;;
     case 'code' : labelText = this.props.t('code') ; break;;
     case 'zip' : labelText = this.props.t('zip') ; break;;
     default: break;
     }
     return <label key={key} >
       { labelText }
       {data[1]  === true? <span> *</span> : ''}
       <br />
       <input
         value={value}
         placeholder={value}
         required={data[1]}
         id={key}
         name={key}
         type='text'
         size="30"
         // value= { this.state.formData[name] }
         onChange={ value =>  this.handleChange(value) }
       />
     </label>;
   };

   Email = (key, data) => {
     let value;
     data[0] ? value = data[0] : value = '';
     return <label key={key} >
       {this.props.t('email')}
       {data[1]  === true? <span> *</span> : ''}
       <br />
       <input
         value={value}
         style={{ textTransform: 'lowercase' }}
         required={data[1]}
         id={key}
         name={key}
         key={key}
         type='email'
         size="30"
         pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$"
         onChange={ event => this.handleChange(event) }
       />
     </label>;
   };

   Number = (key, data) => {
     let value;
     data[0] ? value = data[0] : value = '';
     return <label key={key} >
       { this.props.t('birthyear') }
       {data[1]  === true? <span> *</span> : ''}
       <br />
       <input
         value={value}
         required={data[1]}
         id={key}
         name={key}
         key={key}
         type='number'
         size="30"
         min='1900'
         max='2100'
         onChange={ event => this.handleChange(event) }
       />
     </label>;
   };
   /// still
   Gender = (key, data) => {
     return(
       <div key='gender'>
         <span className="label">
           {this.props.t('gender')}
           {data[1]  === true? <span> *</span> : ''}</span>
         <label key='male' >
           <input type="radio"  name={key} key='male'
             value= 'male'
             // required={data[1]}
             checked={data[0] === 'male'}
             onChange={e => this.handleChange(e)} /> {this.props.t('male')}
         </label>
         <label key='female' >
           <input type="radio" name={key} key='female'
             value= 'female'
             // required={data[1]}
             checked={data[0] === 'female'}
             onChange={e => this.handleChange(e)}  />{this.props.t('female')} </label>
         <label key='divers' >
           <input type="radio" name={key} key='divers'
             value= 'divers'
             // required={data[1]}
             checked={data[0] === 'divers'}
             onChange={e => this.handleChange(e)} /> {this.props.t('divers')} </label>
         {data[1]  === false? <label>
           <input type="radio" name={key} value= '' key='noinput'
             // required={data[1]}
             checked={data[0] === ''}
             onChange={e => this.handleChange(e)} />  {this.props.t('no input')}  </label>: ''}
       </div>
     );
   };


   Textarea = (key, data) => {
     const value = data[0];
     return <label key={key} >
       {this.props.t('extra')}
       {data[1]  === true? <span> *</span> : ''}
       <textarea id={key} name={key} key={key}
         value={value}
         placeholder={value}
         required={data[1]}
         onChange={ e => this.handleChange(e) } />
     </label>;
   };

   DateOfBirth = (key, data) => {
     const value = data[0];
     return <label key={key} >
       {this.props.t('birthday')}
       {data[1]  === true? <span> *</span> : ''}
       <br />
       <input type="date" id={key} name={key}
         value={value}
         key={key}
         required={data[1]}
         onChange={ e => this.handleChange(e) } />
     </label>;
   };

   render() {
     const { formData } = this.state;
     const Form = [];
     // eslint-disable-next-line array-callback-return
     Object.keys(formData).map( key => {
       switch (key) {
       case 'email':  Form.push(this.Email(key, formData[key])); break;
       case 'surname':  Form.push(this.TextField(key, formData[key])); break;
       case 'forename':  Form.push(this.TextField(key, formData[key])); break;
       case 'pseudonym':  Form.push(this.TextField(key, formData[key])); break;
       case 'company':  Form.push(this.TextField(key, formData[key])); break;
       case 'street':  Form.push(this.TextField(key, formData[key])); break;
       case 'city':  Form.push(this.TextField(key, formData[key])); break;
       case 'job':  Form.push(this.TextField(key, formData[key])); break;
       case 'business':  Form.push(this.TextField(key, formData[key])); break;
       case 'position':  Form.push(this.TextField(key, formData[key])); break;
       case 'division':  Form.push(this.TextField(key, formData[key])); break;
       case 'code':  Form.push(this.TextField(key, formData[key])); break;
       case 'phone':  Form.push(this.TextField(key, formData[key])); break;
       case 'zip':  Form.push(this.TextField(key, formData[key])); break;
       case 'birthyear':  Form.push(this.Number(key, formData[key])); break;
       case 'gender':  Form.push(this.Gender(key, formData[key])); break;
       case 'extra':  Form.push(this.Textarea(key, formData[key])); break;
       case 'birthday':  Form.push(this.DateOfBirth(key, formData[key])); break;
       default: break;
       }
     }
     );

     return(
       <div>
         <header>
           <div className="questionnaire-brand" >
             <span className="questionnaire-name">{this.props.t('CNT-Testcenter')} </span>
           </div>
         </header>
         <main className="maincontent textpage">
           <div className={this.props.serverError ? 'error' : 'hidden'} >
             {this.props.t('some thing gone wrong, please try again')}
           </div>
           <p>{this.props.t('Please enter this data before starting')}</p>
           <p className="questionhint">{this.props.t('Required')}</p>
           <div className="data">
             <form onSubmit={this.handleSubmit} id='form'>
               {Form}
               <div className="submit_container">
                 <input type="submit" name="commit"  value={this.props.t('Continue')}
                   className="active" action="answer" id="button" data-disable-with="Create Given answer"
                   style={{
                     display: 'inline-block',
                     padding: '0.5em 1.5em',
                     color:'#fff',
                     background: 'var(--color-mandant)',
                     border: 'none',
                     borderRadius: '1.5em',
                     fontSize: '1em',
                     textTransform: 'uppercase',
                   }}
                 />
               </div>
             </form>
           </div>
           <KeyboardEventHandler
             handleKeys={['enter']}
             onKeyEvent={key => this.clickEnter(key)}
           />
         </main>
       </div>
     );
   }
}

export default withNamespaces()(PersonalData);
