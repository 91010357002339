/* eslint-disable prefer-destructuring */
import React , { Component } from 'react';
import '../App.css';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import Markdown from 'react-markdown';
import { withNamespaces } from 'react-i18next';
import { ParseMarkdown } from '../lib/ParseMarkdown';


class SurveyIntroOutro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isPrivacy: false,
      showPrivacy: false,
      showCheckbox: false
    };
  }

  componentDidMount() {
    if (this.props.survey[0].popup.length > 0 ) {
      this.setState({
        isPrivacy: true
      });
    }
    if (this.props.survey[0].checkbox.length > 0 ) {
      this.setState({
        isPrivacy: true
      });
    } else {
      this.setState({
        submitted: true
      });
    }
  }

  clickEnter = key => {
    if (this.state.submitted === true) {
      this.props.callbackFromIntroOutro();
    }
  };

  render() {
    const { logo_url, title } = this.props;
    const { submitted, isPrivacy, showPrivacy, showCheckbox } = this.state;
    var src = this.props.survey[0];
    var popup = this.props.survey[0].popup[0];
    return(
      <div>
        <header>
          <div className="questionnaire-brand" >
            {logo_url.length > 0 ?  <img src={logo_url}  alt="Logo Fragebogen"  title="CAPTain" /> :
              <span className="questionnaire-name">{title}</span> }
          </div>
        </header>
        <main className="maincontent textpage" >
          <ParseMarkdown source={src} />
          <p className={isPrivacy? '' : 'hidden'}><span className='divHover'  onClick={ () => this.setState({ showPrivacy: !showPrivacy }) }>{this.props.t('Privacy')}</span></p>
          <div className={showPrivacy? '' : 'hidden'} >
            <Markdown source={popup} />
          </div>
          <div className={showCheckbox? 'pleaseagree' : 'hidden' } >
            <input type="checkbox"  id="Datenschutz" onChange={() => this.setState({ submitted: !submitted })} />
            <label htmlFor="Datenschutz">{this.props.survey[0].checkbox}</label>
          </div>
          <div className="submit_container">
            <input disabled={submitted? false : true }
              type="button" name="commit"  value={this.props.t('Continue')}
              className={submitted? 'active' : 'inactive' }
              action="answer" id="button" data-disable-with="Create Given answer"
              onClick={() => this.props.callbackFromIntroOutro()}
            />
          </div>
        </main>
        <KeyboardEventHandler
          handleKeys={['enter']}
          onKeyEvent={key => this.clickEnter(key)}
        />
      </div>
    );
  }
}

export default  withNamespaces()(SurveyIntroOutro);
