/* eslint-disable array-callback-return */
export const SET_DATA = 'SET_DATA';
export const SET_LOADED = 'SET_LOADED';
export const SET_HEADER = 'SET_HEADER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_DUMMY = 'SET_DUMMY';
export const UPDATA_DUMMY = 'UPDATA_DUMMY';
export const UPDATE_PERSONAL_DATA = 'UPDATE_PERSONAL_DATA';

export function setData(data) {      
  return { 
    type: SET_DATA, 
    payload: data 
  };
};


export function setLoaded(loaded) {    
  return { 
    type: SET_LOADED, 
    payload: loaded 
  };
};


export function setHeaderLogo(headerData) {    
  return { 
    type: SET_HEADER, 
    payload: headerData 
  };
};
export function setHeaderText(headerData) {    
  return { 
    type: SET_HEADER, 
    payload: headerData 
  };
};


export function setToken(token) {      
  return { 
    type: SET_TOKEN, 
    payload: token 
  };
};

export function setDummy(dummy) {      
  const data = [];
  dummy.map( survey => 
    survey.questions.map( i => {
      switch(i.kind) {
      case 'freetext': data.push({ kind: i.kind, id: i.id, text: 'Lorem ipsum' }); break;
      case 'sc_ab': data.push({ kind: i.kind, id: i.id, answers: i.answers }); break;
      case 'sc_yesno': data.push({ kind: i.kind, id: i.id, answers: i.answers }); break;
      case 'sc_simple': data.push({ kind: i.kind, id: i.id, answers: i.answers }); break;
      case 'mc_simple': data.push({ kind: i.kind, id: i.id, answers: i.answers }); break;
      case 'sc_matrix': data.push({ kind: i.kind, id: i.id, answers: i.answers, scales: i.scales }); break;
      case 'scale': data.push({ kind: i.kind, id: i.id, answers: i.answers, scales: i.scales }); break;
      default: break;
      }
    }
    ));             
  return { 
    type: SET_DUMMY, 
    payload: data
  };
};



export function updateDummy(data) {   
  return { 
    type: UPDATA_DUMMY, 
    payload: data
  };
};

export function updatePersonalData(data) {   
  return {
    type: UPDATE_PERSONAL_DATA,
    payload: data
  };
}






