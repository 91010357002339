import React, { Component } from 'react';
import { API_ROOT } from '../api-config';
import axios from 'axios';
import { connect } from 'react-redux';
import { setLoaded, setData, setToken, setDummy } from '../reduxActions';
import { history } from '../index';
import i18n from '../i18n';
import { ClipLoader } from 'react-spinners';
import { withNamespaces } from 'react-i18next';


class Login extends Component {
  urlToken = this.props.location.search;
  state = {
    userInput: '',
    invalidToken: false,
    serverError: false,
    UnauthorizedError: false,
    generalError: false,
    loading: false
  }

  componentDidMount() {
    if (this.urlToken.length > 2 ) {
      const token = this.urlToken.substr(3,this.urlToken.length);
      if (this.urlToken[1] === 'a') {
        this.gettoken(token);
      } else {
        this.checkToken(token);
      }
    }
  }

  gettoken = token => {
    this.setState({ loading: true });
    axios({
      method: 'post',
      url: `${API_ROOT}/reactapi/gettoken`,
      data: {
        autotoken: token
      }
    }).then(response => {
      this.checkToken(response.data.token);
    }).catch(error => this.showError(error));
  }

  checkToken = token => {
    this.setState({ loading: true });
    if (!token) {
      token = this.state.userInput;
    }
    axios({
      method: 'post',
      url: `${API_ROOT}/reactapi/getdata`,
      data: {
        token: token
      }
    }).then(response => {
      this.props.setLoaded(true);
      this.props.setData(response.data);
      this.props.setToken(token);
      if (response.data.allow_dummydata === true ) {
        this.props.setDummy(response.data.questionnaires);
      }
      i18n.changeLanguage(response.data.language);
      this.setState({ loaded: true });
      history.push('./app');
    }).catch(error => this.showError(error));
  }

  showError = error => {
    this.setState({ loading: false });
    switch (error.response.status) {
    case 403 : this.setState({ invalidToken: true, userInput: '' }); break;
    case 500 : this.setState({ serverError: true, userInput: '' }); break;
    case 401 : this.setState({ UnauthorizedError: true, userInput: '' }); break;
    default:   this.setState({ generalError: true, userInput: '' }); break;        ;
    }
  }

  onChange = e => {
    const userInput = e.target.value;
    this.setState({ userInput, invalidToken: false });
  }

  clickEnter = key => {
    if (key.key === 'Enter') {
      this.checkToken();
    }
  };

  render() {
    return (this.state.loading ?
      <div className='Loading-Circle'>
        <span className="questionnaire-name">CNT-Testcenter</span>
        <div><ClipLoader
          size={150}
          color={'#1F487C'}
          loading={true}
        />
        </div>
      </div>
      : <div id="contentwrapper">
        <header>
          <div className="questionnaire-brand">
            <span className="questionnaire-name">{this.props.t('CNT-Testcenter')} </span>
          </div>
        </header>
        <main>
          <div className={this.state.invalidToken ? 'error' : 'hidden'}>
            The access code is invalid. Please check your access code and try again.
          </div>
          <div className={this.state.serverError ? 'error' : 'hidden'}>
            An internal error has occurred
          </div>
          <div className={this.state.UnauthorizedError ? 'error' : 'hidden'}>
            The access code is invalid. Please check your access code and try again.
          </div>
          <div className="maincontent login">
            <h2>Hello and welcome to the CNT-Testcenter!</h2>
            <p>
              Please enter your access code for the questionnaire here:
            </p>
            <br />
            <input type="text" name='userInput' onChange={ e => this.onChange(e) } onKeyDown={ key => this.clickEnter(key)} />
            <div className="submit_container">
              <input type="button" name="commit" value="Login" disabled={this.state.userInput.length < 1}
                className={this.state.userInput.length > 0? 'active' : 'inactive' } action="answer" id="button"
                data-disable-with="Login"
                onClick={ () => this.checkToken() }
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setLoaded: loaded => dispatch(setLoaded(loaded)),
  setData: data => dispatch(setData(data)),
  setToken: token => dispatch(setToken(token)),
  setDummy: dummy => dispatch(setDummy(dummy))
});

export default connect(null, mapDispatchToProps)((withNamespaces()(Login)));
