/* eslint-disable array-callback-return */
import React , { Component } from 'react';
import '../App.css';
import McSimple from './Questions/McSimple';
import SaAb from './Questions/ScAb';
import ScSimple from './Questions/ScSimple';
import ScYesNo from './Questions/ScYesNo';
import FreeText from './Questions/FreeText';
import NumberScale from './Questions/NumberScale';
import ScScale from './Questions/ScScale';
import ScMatrix from './Questions/ScMatrix';
import NsGoto from './Questions/NcGoto';
import ScGoto from './Questions/ScGoto';
import { API_ROOT } from '../api-config';
import { connect } from 'react-redux';
import SurveyIntroOutro from './SurveyIntroOutro';
import axios from 'axios';
import { history } from '../index';
import { updateDummy } from '../reduxActions';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextField: 0,
      error: false,
      values: [],
      question_id: null
    };
    this.interval = React.createRef();
  }

  componentDidMount() {
    const nodeToRender = [];
    const modulsLength = this.props.questionnaires.length;
    const { values } = this.state;
    this.props.questionnaires.map( (survey, modulNumber ) => {
      const questionsLength = survey.questions.length ;
      if (survey.intro.length > 0) {
        nodeToRender.push(<SurveyIntroOutro survey={survey.intro} title={survey.title} logo_url={survey.logo_url} callbackFromIntroOutro={this.callbackFromIntroOutro} modul={modulNumber + 1} />);
      }
      if (survey.allow_back === true) {
        survey.questions[0].firstQ = true;
      }
      if (survey.timelimit_seconds > 0) {
        survey.questions[0].startTimer = true;
        survey.questions[ survey.questions.length -1].finshTimer = true;
      }
      if (survey.questions.length > 0) {
        const { module_addcount } = this.props;
        survey.questions.map( (question , questionNumber) => {
          const { allow_dummydata } = this.props;
          const { logo_url, question_addcount, allow_back, allow_later, title, timelimit_seconds } = survey  ;
          nodeToRender.push(this.extractQuestions(question, questionsLength, questionNumber,
            modulsLength, modulNumber,  logo_url, question_addcount,
            allow_back, allow_later, allow_dummydata, title, timelimit_seconds, module_addcount, values
          ));
        });
      }
      if (survey.outro.length > 0) {
        nodeToRender.push(<SurveyIntroOutro survey={survey.outro} title={survey.title} logo_url={survey.logo_url} callbackFromIntroOutro={this.callbackFromIntroOutro} />);
      }
    });
    var filtered = nodeToRender.filter(el => {
      return el != null;
    });
    this.setState({ nodeToRender: filtered , modulsLength });
    if (this.props.questionnaires.length === 0) {
      history.push('/success');
    }
  }

  callbackFromIntroOutro = () => {
    let isLast;
    this.state.nodeToRender.length === this.state.nextField +1  ?isLast = true : isLast = false;
    if (isLast) {
      history.push('/success');
    } else {
      this.setState({ nextField: this.state.nextField + 1 });
    }
  }

  callbackFromQestion = answer => {
    let isLast ;
    this.state.nodeToRender.length === this.state.nextField + 1 ? isLast = true : isLast = false;

    const { values } = this.state;
    // DOKU: This deletes existing answers from the answers array
    if(values.length > 0) {
      const index = values.findIndex(v => v[0].question_id === answer[0].question_id);
      if(index >= 0) {
        values.splice(index, 1);
      }
    }

    values.push(answer);
    const { nodeToRender } = this.state;

    this.setState({ nodeToRender: nodeToRender });
    this.setState({ values: values });
    if (this.props.send_answers) {
      const answers = [];
      const data = {};
      data.token =  this.props.token;
      answer.map(answer => answers.push(answer));
      data.answers = answers;
      data.isLast = isLast;

      axios({
        method: 'post',
        url: `${API_ROOT}/reactapi/setdata`,
        data
      }).catch( () => {
        this.setState({ error: true });
      });
      if (isLast) {
        history.push('/success');
      } else {
        this.setState({ nextField: this.state.nextField + 1 });
      }
      // delete the answer from dummy in store
      if (this.props.allow_dummydata === true) {
        this.updateDummyInStore(data.answers[0].question_id);
      }
    } else if (isLast) {
      history.push('/success');
    } else {
      this.setState({ nextField: this.state.nextField + 1 });
    }
  }

  updateDummyInStore = question_id => {
    const { dummy } = this.props;
    let index;
    dummy.map( item => {
      if (item.id === question_id) {
        index = dummy.indexOf(item);
      }
    }
    );
    dummy.splice(index, 1);
    this.props.updateDummy(dummy);
  }

  stepBack = () => {
    this.setState({ nextField: this.state.nextField - 1 });
  }

  setNewNodes = (id, fromTimeLimit) => {
    // DOKU: called from Jump => use Id as question Id
    // DOKU: called from Time Limit => use Id as Modul Id
    // DOKU: if Id from jump question is -1 then it jumps to the end of the questionnaire
    const { nodeToRender } = this.state ;
    const newArray = [];

    if (fromTimeLimit) {
      for (let index = 0; index < nodeToRender.length; index++) {
        const element = nodeToRender[index];
        if (element.props.modul > id ) {
          newArray.push(element);
        }
      }
    } else if(id === -1) {
      // DOKU: loop through all possible questions to render and push the questions from the next modul to newArray
      // DOKU: modul is the diffrent questionnaires in the question list witch is nodeToRender
      // DOKU: next field seems to be the index of the actual question in nodeToRender
      const nextModulNumber = nodeToRender[this.state.nextField].props.modul + 1;
      for (let index = 0; index < nodeToRender.length; index++) {
        const element = nodeToRender[index];

        if (element.props.modul >= nextModulNumber) {
          newArray.push(element);
        }
      }
    } else {
      var isTargetQuestion = false;
      for (let index = 0; index < nodeToRender.length; index++) {
        const element = nodeToRender[index];
        const key  = parseInt(element.key, 10);
        if(key === id) {
          isTargetQuestion = true;
        }

        if(isTargetQuestion) {
          newArray.push(element);
        }
      }
    }

    if (newArray.length < 1) {
      const data = {};
      data.token =  this.props.token;
      data.time_over = true;
      data.isLast = true;
      axios({
        method: 'post',
        url: `${API_ROOT}/reactapi/setdata`,
        data
      }).catch( () => {
        this.setState({ error: true });
      });
      history.push('/success');
    } else {
      this.setState({
        nodeToRender: newArray,
        nextField: 0
      });
    }
  }

  startTimer = (timelimit_seconds, modulNumber) => {
    // DOKU: stops the timer from getting started multiple times
    this.props.questionnaires[modulNumber - this.props.module_addcount - 1].questions[0].startTimer = false;

    let isLast;
    var timer = timelimit_seconds, minutes, seconds;
    this.interval = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      const node =  document.getElementById('countDown');
      node.innerHTML = minutes + ':' + seconds;
      if (--timer === 0) {
        this.finshTimer();
        const data = {};
        data.token =  this.props.token;
        data.time_over = true;
        this.state.nodeToRender.length === this.state.nextField + 1 ? isLast = true : isLast = false;
        data.isLast = isLast;
        axios({
          method: 'post',
          url: `${API_ROOT}/reactapi/setdata`,
          data
        }).catch( () => {
          this.setState({ error: true });
        });
        if (isLast) {
          history.push('/success');
        } else {
          this.setNewNodes(modulNumber, 'modul');
        }
      }
    }, 1000);
  }

  finshTimer = () => {
    clearInterval(this.interval);
    const node =  document.getElementById('countDown');
    node.innerHTML = '';
  }

  // DOKU: Prepares this data as props for the Question Components.
  extractQuestions = (question , questionsLength, questionNumber,
    modulsLength, modulNumber, logo_url, question_addcount, allow_back, allow_later,
    allow_dummydata, title, timelimit_seconds, module_addcount, values ) => {
    questionsLength = questionsLength + question_addcount;
    questionNumber = questionNumber + 1 + question_addcount;
    modulNumber = modulNumber + 1 + module_addcount;
    modulsLength = modulsLength + module_addcount;
    const bag = { question , questionNumber , questionsLength,
      modulsLength, modulNumber, logo_url, allow_back, allow_later,
      allow_dummydata, title, timelimit_seconds, values };
    const functionsBag = {
      callbackFromQestion: this.callbackFromQestion,
      stepBack : this.stepBack,
      startTimer: this.startTimer,
      finshTimer: this.finshTimer,
      callbackFromJumpQuestion: this.setNewNodes
    };
    switch(bag.question.kind) {
    case 'sc_yesno':    return <ScYesNo key={question.id} modul={modulNumber} bag={bag} functionsBag={functionsBag} />;
    case 'sc_ab':    return <SaAb key={question.id}  modul={modulNumber} bag={bag} functionsBag={functionsBag} />;
    case 'sc_simple':  return <ScSimple key={question.id}  modul={modulNumber} bag={bag} functionsBag={functionsBag} />;
    case 'mc_simple':  return <McSimple key={question.id}  modul={modulNumber} bag={bag} functionsBag={functionsBag}  />;
    case 'freetext':  return <FreeText key={question.id}  modul={modulNumber} bag={bag} functionsBag={functionsBag} />;
    case 'numeric':  return <NumberScale key={question.id}  modul={modulNumber} bag={bag} functionsBag={functionsBag} />;
    case 'sc_matrix':  return <ScMatrix key={question.id}  modul={modulNumber} bag={bag} functionsBag={functionsBag} />;
    case 'scale':  return <ScScale key={question.id}  modul={modulNumber} bag={bag} functionsBag={functionsBag} />;
    case 'nc_goto':  return <NsGoto key={question.id}  modul={modulNumber} bag={bag} functionsBag={functionsBag} />;
    case 'sc_goto':  return <ScGoto key={question.id}  modul={modulNumber} bag={bag} functionsBag={functionsBag} />;
    default: break;
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return(
      <div>
        {this.state.error ?
          <p>{this.props.error[0].text}</p> : <div>
            <p id='countDown'></p>
            {this.state.nodeToRender && this.state.nodeToRender[this.state.nextField] }
          </div> }
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { questionnaires, end, module_addcount, error, allow_dummydata, send_answers } = state.rootReducer.data;
  const { token } = state.tokenReducer;
  const { dummy } = state.dummyReducer;
  return {
    questionnaires, end, token, dummy, module_addcount, error, allow_dummydata, send_answers
  };
};

const mapDispatchToProps = dispatch => ({
  updateDummy: dummy => dispatch(updateDummy(dummy))
});


export default connect(mapStateToProps, mapDispatchToProps)(Content) ;
